import React from "react"

const PageTitle = ({ children, className = "" }) => {
  return (
    <h1
      className={`font-extrabold text-4xl md:5xl lg:text-6xl xl:text-7xl my-5 mx-0 text-center leading-normal font-heading text-primary uppercase tracking-loose ${className}`}
    >
      {children}
    </h1>
  )
}

export default PageTitle
