import React from "react"
import { graphql } from "gatsby"

// import ContactForm from "../components/contactForm"
import Seo from "../components/seo"
import PageTitle from "../components/shared/PageTitle"

const ContactPage = ({ data }) => (
  <div className="">
    <Seo
      title="Contact"
      keywords={[`Sioux Lookout`, `hockey`, `Contact`, `info`]}
    />
    <PageTitle className="my-10">Contact SLMHA</PageTitle>
    <div className="flex flex-col items-center gap-y-8">
      <div>
        <p>For general enquiries please contact:</p>
        <p>
          <span style={{ fontWeight: "bold" }}>Kailey Clark</span>,
          Communications Convener.
        </p>
      </div>
      <div className="grid gap-y-5">
        <p>
          <b>Email</b>
          <br />
          <a href="mailto:slmha.comms@gmail.com?subject=Minor%20Hockey">
            slmha.comms@gmail.com
          </a>
        </p>

        <div>
          <b>Mail</b>
          <div style={{ fontStyle: "italic" }}>
            Sioux Lookout Minor Hockey Association
            <br /> PO Box 1085
            <br />
            Sioux Lookout, ON
            <br />
            P8T 1A0
            <br /> Canada
          </div>
        </div>

        {/* <p style={{ textAlign: "center", paddingTop: "25px" }}>
          <i>Or use the contact form below:</i>
        </p> */}
      </div>
    </div>

    {/* <ContactForm /> */}
  </div>
)

export default ContactPage

export const pageQuery = graphql`
  query contactQuery {
    contactImg: file(relativePath: { eq: "sampleImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
